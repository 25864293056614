<template>
  <div class="middle-box text-center">
    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="旧密码" prop="oldPass">
        <el-input type="text" v-model="ruleForm.oldPass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPass">
        <el-input type="password" v-model="ruleForm.newPass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input type="password" v-model="ruleForm.checkNewPass" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">修改密码</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import request from "@/util/request";

export default {
  name: "Index",
  data() {
    let validateOldPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error("请输入当前密码"));
      } else {
        request({
          url: "/user/checkPassword.do",
          method: "POST",
          data: this.ruleForm.oldPass

        }, res => {
          if (res.code === 200)
            callback();
          else
            callback(new Error(res.msg))
        }, err => {
        }, this.$store.getters.token());
      }
    };
    let validateNewPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (!/^[a-zA-Z][a-zA-Z0-9]{7,}$/g.test(value)) {
        callback(new Error("必须为字母开头加数字且长度不小于8位"))
      } else
        callback();

    };
    let validateCheckPass = (rule, value, callback) => {
      if (this.ruleForm.checkNewPass === '')
        callback(new Error("确认密码不能为空"));
      else if (this.ruleForm.checkNewPass !== this.ruleForm.newPass) {

        callback(new Error("密码不一致"))
      } else
        callback()
    };
    return {
      ruleForm: {
        oldPass: "",
        newPass: "",
        checkNewPass: ""
      },
      rules: {
        oldPass: [{validator: validateOldPass, trigger: 'blur'}],
        newPass: [{validator: validateNewPass, trigger: 'blur'}],
        checkPass: [{validator: validateCheckPass, trigger: 'blur'}]
      }
    }
  },
  methods: {

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          request({
            url: "/user/resetPwd.do",
            method: "POST",
            data: this.ruleForm.newPass

          }, res => {
            if (res.code === 200) {
              this.$message.success(res.msg)
              this.$router.replace("/login")
            } else
              this.$message.success(res.msg)
          }, err => {
          }, this.$store.getters.token())
        } else {
          return false;
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped>

</style>